import React, {
  useState,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import format from 'string-template';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';

import { ReactComponent as CreateIcon } from '../../../../../assets/icons/v2/creation-plus-circle.svg';
import useQuickSearch from '../../../../../hooks/useQuickSearch';
import useComponentMounted from '../../../../../hooks/useComponentMounted';
import {
  HeaderRow,
  TitleContainer,
  Title,
} from '../../../../../components/v2/Header';
import CollapsibleSelect from '../../../../../components/v2/CollapsibleSelect';
import {
  PrimaryButton,
} from '../../../../../components/Button/ActionButtons';
import { CoachingActivity } from '../../../../../utils/log';
import useLogger from '../../../../../hooks/useLogger';
import Exercise from '../../../../Model/Exercise';
import {
  TagCategory,
} from '../../../../Model/ExerciseTag';
import useToast from '../../../../hooks/useToast';
import ExerciseContext, { withExerciseContextReady } from '../../../../context/ExerciseContext';
import ExerciseTagContext from '../../../../context/ExerciseTagContext';
import QuickSearchToolbar from '../../../../components/QuickSearchToolbar';
import LabelCheckbox from '../../../../components/LabelCheckbox';
import GenericDataGrid from '../../../../components/GenericDataGrid';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import {
  ActionType,
  canPerformAction,
  isModalViewAction,
} from '../utils';

import getColumns from './columns';
import ActionButtons from './ActionButtons';
import {
  TagList,
  ExerciseTag,
  ExerciseName,
  Thumbnail,
} from './styles';
import texts from './texts';

const TAGS_PER_ROW = 2;
const EQUIPMENTS_PER_ROW = 1;

const ExercisesTable = ({
  selectedExercise,
  actionPerformed,
  handleActionClick,
  isWorkoutDesignerView,
  isExerciseChangeView,
}) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);

  const {
    exercises,
    activeExercises,
    showArchivedExercises,
    OnChangeCheckBox,
    isLoading,
  } = useContext(ExerciseContext);
  const {
    tags: exerciseTags,
  } = useContext(ExerciseTagContext);

  const isComponentMountedRef = useComponentMounted();

  const { showToast } = useToast();
  const { logCoachingActivity } = useLogger();

  // TODO: Move this memoized list to the Exercise Context when we need to reuse the collapsible select filter
  // Categorize the tag options under the defined tag categories
  const categorizedTagOptions = useMemo(() => {
    const categories = Object.keys(TagCategory).reduce((acc, category) => {
      acc[category] = [];
      return acc;
    }, {});

    exerciseTags.forEach((tag) => {
      const {
        id,
        tag: tagName,
        category,
      } = tag;
      if (categories[category]) {
        categories[category].push({ id, label: tagName });
      }
    });

    // Transform categories object into array of objects with category and items
    return Object.keys(categories).map((category) => ({
      category: texts.tagCategoryOption[category || ''],
      items: categories[category],
    }));
  }, [
    exerciseTags,
  ]);

  const handleArchiveClick = useCallback(async () => {
    setIsProcessing(true);
    await selectedExercise.updateArchiveStatus(!selectedExercise.current);
    logCoachingActivity(selectedExercise.current ? CoachingActivity.ARCHIVED_EXERCISE
      : CoachingActivity.UNARCHIVED_EXERCISE, { exerciseId: selectedExercise.id });
    if (isComponentMountedRef.current) {
      setIsProcessing(false);
      showToast(format(texts.successfullyProcessed, {
        action: texts.action[actionPerformed],
      }));
      handleActionClick(null, null);
    }
  }, [
    showToast,
    isComponentMountedRef,
    actionPerformed,
    selectedExercise,
    handleActionClick,
    logCoachingActivity,
  ]);

  const renderExerciseName = useCallback(({ row: { name, videoPreviewThumbnail } = {} }) => (
    <ExerciseName>
      {(isWorkoutDesignerView || isExerciseChangeView) && videoPreviewThumbnail && (
        <Thumbnail src={videoPreviewThumbnail} alt={name} />
      )}
      <Tooltip title={name} arrow>
        <span>
          {name}
        </span>
      </Tooltip>
    </ExerciseName>

  ), [
    isWorkoutDesignerView,
    isExerciseChangeView,
  ]);

  // Render the tags cell of each row.
  const renderTags = useCallback(({ row: { tags } = {} }) => {
    let tagsCell = texts.emptyText;

    // If we have tags, then we render them with different styles.
    if (tags.length > 0) {
      const initialTags = tags.slice(0, TAGS_PER_ROW);
      const remainingTags = tags.slice(TAGS_PER_ROW, tags.length);
      const tooltipText = remainingTags.map(({ tag }) => tag).join(', ');

      tagsCell = (
        <TagList>
          {initialTags.map(({ id, tag }) => (
            <ExerciseTag key={id}>{tag}</ExerciseTag>
          ))}
          {!!remainingTags.length && (
            <Tooltip title={tooltipText} placement="top" arrow>
              <ExerciseTag>{format(texts.moreTags, { amount: remainingTags.length })}</ExerciseTag>
            </Tooltip>
          )}
        </TagList>
      );
    }

    return tagsCell;
  }, []);

  const renderEquipments = useCallback(({ row: { tags } = {} }) => {
    let equipmentsCell = texts.emptyText;

    if (tags.length > 0) {
      const equipments = tags.filter(({ category }) => category === TagCategory.EQUIPMENT);
      if (equipments.length === 0) return equipmentsCell;

      const initialEquipment = equipments.slice(0, EQUIPMENTS_PER_ROW);
      const remainingEquipments = equipments.length > 1 ? equipments.slice(EQUIPMENTS_PER_ROW, equipments.length) : [];
      const tooltipText = remainingEquipments.map(({ tag }) => tag).join(', ');

      equipmentsCell = (
        <TagList>
          {initialEquipment.map(({ id, tag }) => (
            <ExerciseTag key={id}>{tag}</ExerciseTag>
          ))}
          {!!remainingEquipments.length && (
            <Tooltip title={tooltipText} placement="top" arrow>
              <ExerciseTag>{format(texts.moreTags, { amount: remainingEquipments.length })}</ExerciseTag>
            </Tooltip>
          )}
        </TagList>
      );
    }

    return equipmentsCell;
  }, []);

  const renderActions = useCallback(({ row }) => (
    <ActionButtons
      row={row}
      handleActionClick={handleActionClick}
      isWorkoutDesignerView={isWorkoutDesignerView}
      isExerciseChangeView={isExerciseChangeView}
    />
  ), [
    handleActionClick,
    isWorkoutDesignerView,
    isExerciseChangeView,
  ]);

  const columns = getColumns({
    exerciseNameRenderCell: renderExerciseName,
    tagsRenderCell: renderTags,
    equipmentsRenderCell: renderEquipments,
    actionsRenderCell: renderActions,
    isWorkoutDesignerView,
    isExerciseChangeView,
  });

  // Get filtered rows for toolbar search value
  const {
    filteredRows: quickSearchRows,
    toolbarProps,
  } = useQuickSearch(isWorkoutDesignerView ? activeExercises : exercises, columns);

  /*
   * If tags are selected, only rows containing all selected tags are included in the filtered result,
   * otherwise all rows are included.
   */
  const filteredRows = useMemo(() => (
    selectedTags.length > 0
      ? quickSearchRows.filter((row) => (
        selectedTags.every((tag) => row.tags.some((exerciseTag) => exerciseTag.id === tag))
      ))
      : quickSearchRows
  ), [
    quickSearchRows,
    selectedTags,
  ]);

  const filterTools = useMemo(() => {
    const filters = [];
    if (!isWorkoutDesignerView && !isExerciseChangeView) {
      filters.push({
        Component: LabelCheckbox,
        id: 'archived-exercises-filter',
        props: {
          isChecked: showArchivedExercises,
          description: texts.archivedCheckbox,
          onChange: (isChecked) => OnChangeCheckBox(isChecked),
        },
      });
    }
    filters.push({
      Component: CollapsibleSelect,
      id: 'tags-filter',
      props: {
        options: categorizedTagOptions,
        onChange: (options) => setSelectedTags(options),
        selectLabel: 'Filter Exercises',
      },
    });
    return filters;
  }, [
    categorizedTagOptions,
    isWorkoutDesignerView,
    showArchivedExercises,
    isExerciseChangeView,
    OnChangeCheckBox,
  ]);

  return (
    <>
      {!isWorkoutDesignerView && !isExerciseChangeView && (
        <HeaderRow>
          <TitleContainer>
            <Title>{texts.title}</Title>
          </TitleContainer>
          <PrimaryButton
            onClick={() => handleActionClick(null, ActionType.CREATE)}
            icon={<CreateIcon />}
          >
            {texts.newExercise}
          </PrimaryButton>
        </HeaderRow>
      )}
      <GenericDataGrid
        rows={filteredRows}
        columns={columns}
        components={{
          Toolbar: QuickSearchToolbar,
        }}
        componentsProps={{
          toolbar: {
            ...toolbarProps,
            placeholder: texts.searchPlaceholder,
            filterTools,
          },
        }}
      />
      {canPerformAction(actionPerformed, selectedExercise) && (
        <ConfirmDialog
          isOpen={isModalViewAction(actionPerformed)}
          onConfirm={handleArchiveClick}
          onCancel={() => handleActionClick(null, null)}
          dialogTexts={{
            title: format(texts.actionText, {
              action: texts.action[actionPerformed],
              exercise: selectedExercise.name,
            }),
          }}
        />
      )}
      <LoadingOverlay isLoading={isProcessing || isLoading} />
    </>
  );
};

ExercisesTable.propTypes = {
  selectedExercise: PropTypes.instanceOf(Exercise),
  actionPerformed: PropTypes.string,
  handleActionClick: PropTypes.func,
  isWorkoutDesignerView: PropTypes.bool,
  isExerciseChangeView: PropTypes.bool,
};

ExercisesTable.defaultProps = {
  selectedExercise: null,
  actionPerformed: null,
  handleActionClick: () => { },
  isWorkoutDesignerView: false,
  isExerciseChangeView: false,
};

export default compose(
  withExerciseContextReady,
  observer,
)(ExercisesTable);

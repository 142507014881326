import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import PropTypes, { object } from 'prop-types';
import { Container as DraggableContainer } from 'react-smooth-dnd';

import Activity, { ActivityTypes } from '../../../../../../Model/Activity';
import { convertIndexToLetter } from '../../../../../../utils/text';
import {
  addItem,
  moveItem,
  removeItem,
  replaceItem,
} from '../../../../../../utils/array';
import { PrimaryButton } from '../../../../../../components/Button/ActionButtons';
import { ReactComponent as CreateIcon } from '../../../../../../assets/icons/v2/creation-plus-circle.svg';
import ActivityModal from '../../ActivityModal/ActivityModal';
import AddCircuitModal from '../../AddCircuitModal';
import RestModal from '../../components/RestModal';
import { getActivity, isCircuit } from '../../utils';

import { EditorActionType } from '../utils';
import {
  containerStyle,
  ActionButtonContainer,
} from './styles';
import texts from './texts.json';
import ActivityComp from './Activity';
import Circuit from './Circuit';

const Activities = ({
  activities,
  onActivitiesChange,
  setShowExercisesTable,
  selectedExercise,
  showActivityModal,
  setShowActivityModal,
  setSelectedExercise,
}) => {
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedCircuitIndex, setSelectedCircuitIndex] = useState(null);
  const [selectedActivityIndex, setSelectedActivityIndex] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [shouldResetExerciseData, setShouldResetExerciseData] = useState(false);

  const ExerciseData = useMemo(() => {
    if (shouldResetExerciseData) {
      return selectedExercise;
    }
    if (isUpdate && selectedAction === EditorActionType.ACTIVITY) {
      if (selectedCircuitIndex !== null) {
        return {
          ...activities[selectedCircuitIndex]?.activities[selectedActivityIndex],
        };
      }
      return {
        ...activities[selectedActivityIndex]?.activities[0],
        rounds: activities[selectedActivityIndex]?.rounds,
      };
    }
    return selectedExercise;
  }, [
    activities,
    selectedCircuitIndex,
    selectedActivityIndex,
    selectedExercise,
    selectedAction,
    isUpdate,
    shouldResetExerciseData,
  ]);

  const onDropActivity = useCallback(
    (
      removedIndex,
      addedIndex,
      activity,
      activitiesToUpdate = activities,
      isDroppingToCircuit = false,
    ) => {
      if (removedIndex !== null && addedIndex !== null) {
        moveItem(activitiesToUpdate, removedIndex, addedIndex);
      } else if (removedIndex !== null) {
        removeItem(activitiesToUpdate, removedIndex);
      } else if (addedIndex !== null) {
        let activityToAdd = activity;
        if (isDroppingToCircuit) {
          // If we are adding to a circuit, we need to add just the activity without the wrapping circuit
          activityToAdd = getActivity(activity);
        } else if (activity.type !== ActivityTypes.REST) {
          // If we are adding a activity to root, we need to wrap it in a circuit
          activityToAdd = new Activity({
            type: ActivityTypes.CIRCUIT,
            activities: [activity],
          });
        }
        addItem(activitiesToUpdate, activityToAdd, addedIndex);
      }
      onActivitiesChange(activities.slice());
    }, [activities, onActivitiesChange],
  );

  const onRemoveActivity = useCallback((activityIndex, circuitIndex) => {
    const newActivities = activities.slice();
    // If activityIndex is undefined, remove the whole circuit
    if (activityIndex === undefined) {
      removeItem(newActivities, circuitIndex);
    } else {
      removeItem(circuitIndex !== undefined ? newActivities[circuitIndex].activities : newActivities, activityIndex);
    }
    onActivitiesChange(newActivities);
  }, [activities, onActivitiesChange]);

  const onDuplicateActivity = useCallback((activityIndex, circuitIndex) => {
    const newActivities = activities.slice();
    // If activityIndex is undefined, duplicate the whole circuit
    if (activityIndex === undefined) {
      addItem(newActivities, activities[circuitIndex], circuitIndex);
    } else {
      const selectedActivities = circuitIndex !== undefined ? newActivities[circuitIndex].activities : newActivities;
      const activityToDuplicate = selectedActivities[activityIndex];
      addItem(selectedActivities, activityToDuplicate, activityIndex);
    }
    onActivitiesChange(newActivities);
  }, [activities, onActivitiesChange]);

  const onCloseModal = useCallback(() => {
    setShowActivityModal(false);
    setSelectedActivityIndex(null);
    if (isUpdate) {
      setSelectedCircuitIndex(null);
    }
    setIsUpdate(false);
    setSelectedAction(EditorActionType.ACTIVITY);
    setShouldResetExerciseData(false);
  }, [
    setShowActivityModal,
    isUpdate,
  ]);

  const onAddUpdateActivity = useCallback((newActivities) => {
    /**
     * If we are adding new activity (Rest, Circuit and Activities not inside the circuit),
     * we add them to the end of the activity list.
     * If we are adding activities to a circuit, we add them to the end of the circuit's activity list.
     */
    if (!isUpdate) {
      if (selectedCircuitIndex === null) {
        onActivitiesChange([...activities, ...newActivities]);
      } else {
        onActivitiesChange(
          activities.map((activity, index) => {
            if (index === selectedCircuitIndex) {
              return new Activity({
                ...activity,
                activities: [...activity.activities, ...newActivities],
              });
            }
            return activity;
          }),
        );
      }
    }
    /**
     * If we are updating an activity (Rest, Circuit and Activities not inside the circuit),
     * we replace the old one with the new activities.
     * If we are updating activities inside a circuit,
     * we replace the old activity list of the circuit with the new activity list.
     */
    if (isUpdate) {
      if (selectedCircuitIndex === null) {
        onActivitiesChange(replaceItem(activities, selectedActivityIndex, newActivities));
      } else {
        onActivitiesChange(
          activities.map((activity, index) => {
            if (index === selectedCircuitIndex) {
              return new Activity({
                ...activity,
                activities: activity.activities.flatMap((subActivity, subIndex) => {
                  if (subIndex === selectedActivityIndex) {
                    return newActivities.map((activityToAdd) => new Activity(activityToAdd));
                  }
                  return subActivity;
                }),
              });
            }
            return activity;
          }),
        );
      }
    }
    onCloseModal();
  }, [
    activities,
    onActivitiesChange,
    isUpdate,
    onCloseModal,
    selectedCircuitIndex,
    selectedActivityIndex,
  ]);

  const onClickActivity = (circuitIndex) => {
    setSelectedAction(EditorActionType.ACTIVITY);
    setShowExercisesTable(true);
    setSelectedCircuitIndex(circuitIndex);
  };

  const onClickRest = () => {
    setSelectedAction(EditorActionType.REST);
    setSelectedActivityIndex(null);
    setSelectedCircuitIndex(null);
  };

  const onClickCircuit = () => {
    setSelectedAction(EditorActionType.CIRCUIT);
    setSelectedActivityIndex(null);
    setSelectedCircuitIndex(null);
  };

  const onClickEdit = (activityType, index, circuitIndex = null) => {
    if (activityType === EditorActionType.ACTIVITY) {
      setShowActivityModal(true);
    }
    setSelectedActivityIndex(index);
    setSelectedCircuitIndex(circuitIndex);
    setIsUpdate(true);
    setSelectedAction(activityType);
  };

  return (
    <>
      <DraggableContainer
        dragHandleSelector=".drag-handle"
        groupName="activities"
        lockAxis="y"
        onDrop={({ removedIndex, addedIndex, payload }) => onDropActivity(removedIndex, addedIndex, payload)}
        getChildPayload={(index) => activities[index]}
        style={containerStyle}
      >
        {activities.map((activity, index) => {
          const activityLetter = convertIndexToLetter(index);
          if (isCircuit(activity)) {
            const showRestWarning = index !== activities.length - 1 // this isn't the last activity of the workout
              && activity.activities.length > 0 // Check that this CIRCUIT has activities inside
              && !activity.activities[activity.activities.length - 1].restTime
              && activities[index + 1].type !== ActivityTypes.REST;

            return (
              <Circuit
                activity={activity}
                circuitTag={activityLetter}
                showRestWarning={showRestWarning}
                key={activityLetter}
                circuitIndex={index}
                onDropActivity={onDropActivity}
                onClickActivity={onClickActivity}
                onClickEdit={onClickEdit}
                onRemoveActivity={onRemoveActivity}
                onDuplicateActivity={onDuplicateActivity}
              />
            );
          }
          const showRestWarning = index !== activities.length - 1 // this isn't the last activity of the workout
            && activity?.activities
            && !activity.activities[0].restTime // this activity doesn't have a rest time
            && activities[index + 1].type !== ActivityTypes.REST; // the next activity isn't a rest activity
          return (
            <ActivityComp
              // we need to pass the unwrapped activity to the Activity component
              activity={getActivity(activity)}
              // pass the rounds explicitly as it's only used for display purposes for activities outside of circuits
              rounds={activity.rounds}
              tag={activityLetter}
              key={activityLetter}
              showRestWarning={showRestWarning}
              onRemove={() => onRemoveActivity(index)}
              onDuplicate={() => onDuplicateActivity(index)}
              onClickEdit={
                () => onClickEdit(activity.type === ActivityTypes.REST
                  ? EditorActionType.REST : EditorActionType.ACTIVITY, index)
              }
            />
          );
        })}
      </DraggableContainer>
      <ActionButtonContainer>
        <PrimaryButton icon={<CreateIcon />} onClick={() => onClickActivity(null)}>
          {texts.button.activity}
        </PrimaryButton>
        <PrimaryButton icon={<CreateIcon />} onClick={onClickCircuit}>
          {texts.button.circuit}
        </PrimaryButton>
        <PrimaryButton icon={<CreateIcon />} onClick={onClickRest}>
          {texts.button.rest}
        </PrimaryButton>
      </ActionButtonContainer>
      {(showActivityModal) && (
        <ActivityModal
          showModal={showActivityModal}
          onClose={onCloseModal}
          exercise={ExerciseData}
          onAddActivity={onAddUpdateActivity}
          isUpdate={isUpdate}
          isCircuitActivity={!!activities[selectedCircuitIndex]?.name}
          setSelectedExercise={setSelectedExercise}
          setShouldResetExerciseData={setShouldResetExerciseData}
        />
      )}
      {selectedAction === EditorActionType.CIRCUIT && (
        <AddCircuitModal
          onClose={onCloseModal}
          showModal={selectedAction === EditorActionType.CIRCUIT}
          onAddCircuit={onAddUpdateActivity}
          circuitData={activities[selectedActivityIndex] || null}
        />
      )}
      {selectedAction === EditorActionType.REST && (
        <RestModal
          onClose={onCloseModal}
          isOpen={selectedAction === EditorActionType.REST}
          onSave={onAddUpdateActivity}
          activity={activities[selectedActivityIndex] || null}
        />
      )}
    </>
  );
};

Activities.propTypes = {
  activities: PropTypes.arrayOf(object).isRequired,
  selectedExercise: PropTypes.object,
  onActivitiesChange: PropTypes.func.isRequired,
  setShowExercisesTable: PropTypes.func.isRequired,
  showActivityModal: PropTypes.bool.isRequired,
  setShowActivityModal: PropTypes.func.isRequired,
  setSelectedExercise: PropTypes.func.isRequired,
};

Activities.defaultProps = {
  selectedExercise: null,
};

export default Activities;

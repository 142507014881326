import React, {
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import format from 'string-template';
import {
  Field,
  Formik,
} from 'formik';

import Activity, { ActivityTypes } from '../../../../../Model/Activity';
import { ReactComponent as CreateIcon } from '../../../../../assets/icons/v2/creation-plus-circle.svg';
import { PrimaryButton } from '../../../../../components/Button/ActionButtons';
import DialogRoundedModal from '../../../../../components/DialogRoundedModal';
import Select from '../../../../../components/Select';
import InputLabel from '../../../../../components/v2/InputLabel';
import RadioButtonGroup from '../../../../components/RadioButtonGroup';
import ExerciseDetail from '../components/ExerciseDetails';
import DialogModal from '../../../../../components/DialogModal';
import ExercisesTable from '../../Exercises/ExercisesTable';
import { transformVideoUrl } from '../../Exercises/ExerciseEditor/VideoHandler/utils';
import {
  TimeUnit,
  getSecondsFrom,
} from '../../../../../utils/time';
import {
  DurationType,
  SideType,
} from '../utils';

import {
  initialValues as startValues,
  validationSchema,
} from './validation';
import FormField from './formFields';
import {
  StyledCircuitIcon,
  FormContainer,
  ContentContainer,
  VideoPreviewContainer,
  VideoPreview,
  ButtonContainer,
  SelectContainer,
  InputSelectContainer,
  SetContainer,
  VideoPreviewTitle,
  SubContainer,
  StyledFormikInput,
  RadioGroupContainer,
  ExerciseChangeContainer,
  Title,
} from './styles';
import texts from './texts';

const sideOptions = [
  { value: '', label: texts.none },
  ...Object.values(SideType).map((side) => ({ value: side, label: texts.options.side[side] })),
];
const timeOptions = Object.values(TimeUnit).map((time) => ({ value: time, label: texts.options.time[time] }));
const durationOptions = Object.values(DurationType).map(
  (duration) => ({ value: duration, label: texts.options.duration[duration] }),
);

const ActivityModal = ({
  showModal,
  onClose,
  exercise,
  onAddActivity,
  isUpdate,
  isCircuitActivity,
  setSelectedExercise,
  setShouldResetExerciseData,
}) => {
  const [selectedDuration, setSelectedDuration] = useState(isUpdate ? exercise?.type : DurationType.TIMED);
  const [showExerciseChangeModal, setShowExerciseChangeModal] = useState(false);

  const title = useMemo(() => {
    if (isCircuitActivity) {
      return format(isUpdate
        ? texts.title.editCircuitActivity : texts.title.addCircuitActivity, { activityName: exercise.name });
    }
    return format(isUpdate
      ? texts.title.editRootActivity : texts.title.addRootActivity, { activityName: exercise.name });
  }, [
    isUpdate,
    isCircuitActivity,
    exercise,
  ]);

  const onAddUpdateActivity = (values) => {
    const sides = [];
    /*
     * If the activity is a LEFT_THEN_RIGHT_SIDE and TIMED, we need to add two activities, one for each side.
     * Otherwise, we just add the selected side.
    */
    if (values[FormField.SIDE] === SideType.LEFT_THEN_RIGHT_SIDE && selectedDuration === DurationType.TIMED) {
      sides.push(SideType.LEFT_SIDE, SideType.RIGHT_SIDE);
    } else {
      sides.push(values[FormField.SIDE]);
    }
    // Create an activity for each side selected
    const activityData = sides.map((side) => ({
      type: selectedDuration,
      name: exercise?.name,
      note: values[FormField.NOTE],
      side,
      description: exercise?.description,
      restTime: getSecondsFrom(values[FormField.REST_TIME], values[FormField.REST_TIME_UNIT]),
      restTimeDisplayUnit: values[FormField.REST_TIME_UNIT],
      exerciseId: exercise?.id || exercise?.exerciseId,
      tags: exercise?.tags,
      videoUrl: exercise?.videoUrl,
      videoPreviewUrl: exercise?.videoPreviewUrl,
      videoPreviewThumbnail: exercise?.videoPreviewThumbnail,
      duration: getSecondsFrom(values[FormField.SET_TIME], values[FormField.SET_TIME_UNIT]),
      durationDisplayUnit: values[FormField.SET_TIME_UNIT],
      repetitions: values[FormField.REPETITION],
    }));
    /**
     * If activities that are going to add is not a circuit activity,
     * we need to wrapped that activity inside a CIRCUIT with the number of rounds selected.
     */
    const activitiesToAdd = activityData.map((activity) => {
      if (!isCircuitActivity) {
        return new Activity({
          type: ActivityTypes.CIRCUIT,
          rounds: values[FormField.SET],
          activities: [activity],
        });
      }
      return new Activity(activity);
    });
    onAddActivity(activitiesToAdd);
  };

  const renderSetComponent = () => (
    <SetContainer>
      {!isCircuitActivity && (
        <StyledFormikInput
          name={FormField.SET}
          label={`${texts.fieldLabel[FormField.SET]}:`}
          type="number"
        />
      )}
      {selectedDuration === DurationType.TIMED ? (
        <SubContainer>
          <InputLabel>{`${texts.fieldLabel[FormField.SET_TIME]}:`}</InputLabel>
          <InputSelectContainer>
            <StyledFormikInput
              name={FormField.SET_TIME}
              type="number"
              width="150"
            />
            <SelectContainer>
              <Field
                name={FormField.SET_TIME_UNIT}
                component={({ field }) => (
                  <Select
                    {...field}
                    options={timeOptions}
                  />
                )}
              />
            </SelectContainer>
          </InputSelectContainer>
        </SubContainer>
      ) : (
        <SubContainer>
          <StyledFormikInput
            name={FormField.REPETITION}
            label={`${texts.fieldLabel[FormField.REPETITION]}:`}
            type="number"
            width="150"
          />
        </SubContainer>
      )}
    </SetContainer>
  );

  const handleActionClick = (selectedExercise) => {
    setShowExerciseChangeModal(false);
    setSelectedExercise(selectedExercise);
    setShouldResetExerciseData(true);
  };

  return (
    <>
      <DialogRoundedModal
        title={title}
        IconComponent={<StyledCircuitIcon />}
        open={showModal}
        onClose={onClose}
        fullWidth
        maxWidth="md"
      >
        <Formik
          initialValues={startValues(exercise)}
          validationSchema={validationSchema(selectedDuration)}
          onSubmit={onAddUpdateActivity}
          enableReinitialize
        >
          {({ isSubmitting, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FormContainer>
                <ContentContainer>
                  <InputLabel>{texts.exercise}</InputLabel>
                  <ExerciseDetail
                    exerciseData={exercise}
                    setShowExerciseChangeModal={setShowExerciseChangeModal}
                    isUpdate={isUpdate}
                  />
                  <RadioGroupContainer>
                    <InputLabel>{`${texts.fieldLabel[FormField.DURATION]}:`}</InputLabel>
                    <RadioButtonGroup
                      options={durationOptions}
                      onOptionChange={setSelectedDuration}
                      selectedOption={selectedDuration}
                    />
                  </RadioGroupContainer>
                  <SelectContainer>
                    <Field
                      name={FormField.SIDE}
                      component={({ field }) => (
                        <Select
                          {...field}
                          options={sideOptions}
                          label={`${texts.fieldLabel[FormField.SIDE]}:`}
                        />
                      )}
                    />
                  </SelectContainer>
                  {renderSetComponent()}
                  <SubContainer>
                    <InputLabel>{`${texts.fieldLabel[FormField.REST_TIME]}:`}</InputLabel>
                    <InputSelectContainer>
                      <StyledFormikInput
                        name={FormField.REST_TIME}
                        type="number"
                        width="150"
                      />
                      <SelectContainer>
                        <Field
                          name={FormField.REST_TIME_UNIT}
                          component={({ field }) => (
                            <Select
                              {...field}
                              options={timeOptions}
                            />
                          )}
                        />
                      </SelectContainer>
                    </InputSelectContainer>
                  </SubContainer>
                  <StyledFormikInput
                    name={FormField.NOTE}
                    label={`${texts.fieldLabel[FormField.NOTE]}:`}
                    multiline
                    rows={3}
                  />
                </ContentContainer>
                {(exercise.videoPreviewUrl || exercise.videoUrl) && (
                  <VideoPreviewContainer>
                    <InputLabel>{texts.exerciseVideoPreview}</InputLabel>
                    <VideoPreview
                      src={transformVideoUrl(exercise.videoPreviewUrl || exercise.videoUrl)}
                    />
                    <VideoPreviewTitle>{exercise.name}</VideoPreviewTitle>
                  </VideoPreviewContainer>
                )}
              </FormContainer>
              <ButtonContainer>
                <PrimaryButton
                  icon={<CreateIcon />}
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isUpdate ? texts.button.update : texts.button.add}
                </PrimaryButton>
              </ButtonContainer>
            </form>
          )}
        </Formik>
      </DialogRoundedModal>
      {showExerciseChangeModal && (
        <DialogModal
          open={showExerciseChangeModal}
          onClose={() => setShowExerciseChangeModal(false)}
          fullWidth
          maxWidth="md"
        >
          <ExerciseChangeContainer>
            <Title>{texts.exerciseChange}</Title>
            <ExerciseDetail
              exerciseData={exercise}
              isUpdate={isUpdate}
              isExerciseChangeView
            />
            <ExercisesTable
              isExerciseChangeView
              handleActionClick={handleActionClick}
            />
          </ExerciseChangeContainer>
        </DialogModal>
      )}
    </>
  );
};

ActivityModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  exercise: PropTypes.object.isRequired,
  onAddActivity: PropTypes.func.isRequired,
  isUpdate: PropTypes.bool.isRequired,
  isCircuitActivity: PropTypes.bool.isRequired,
  setSelectedExercise: PropTypes.func.isRequired,
  setShouldResetExerciseData: PropTypes.func.isRequired,
};

export default ActivityModal;

import React, {
  useEffect,
  useCallback,
  useState,
  useContext,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';
import FullCalendar from '@fullcalendar/react';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import {
  Tooltip,
  Menu,
  MenuItem,
} from '@mui/material';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import * as Sentry from '@sentry/browser';
import format from 'string-template';

import useComponentMounted from '../../../../hooks/useComponentMounted';
import WorkoutAssignment, { workoutAssignmentStatuses } from '../../../../Model/WorkoutAssignment';
import useToast from '../../../hooks/useToast';
import useUserDoc from '../../../hooks/useUserDoc';
import useUpdateInteraction from '../../../hooks/useUpdateInteraction';
import { ActionType } from '../../../pages/WorkoutsManager/Workouts/utils';
import ExternalCoachContext from '../../../context/ExternalCoachContext';
import LoadingOverlay from '../../LoadingOverlay';
import WorkoutAssignmentModal from '../WorkoutAssignmentModal';
import WorkoutDeletionModal from '../WorkoutDeletionModal';
import text from '../texts.json';
import ProgramAssignmentModal from '../ProgramAssignmentModal';
import MobileViewModal from '../../../pages/ClientInfo/components/MobileView';
import config from '../../../../config';
import { CoachingActivity } from '../../../../utils/log';
import useLogger from '../../../../hooks/useLogger';
import WorkoutEditorModal from '../../WorkoutEditorModal';
import CalendarDayView from './CalendarDayView';
import {
  EventContainer,
  EventTitle,
  CalendarWrapper,
  StyledIconButton,
  StyledCheckCircleOutline,
  StyledRadioButtonUnchecked,
} from './styles';
import texts from './texts.json';
import './assets/css/styles.css';

const WorkoutCalendar = ({
  clientId,
}) => {
  const [workoutAssignments, setWorkoutAssignments] = useState({ docs: [] });
  const [showLoading, setShowLoading] = useState(true);
  const [showWorkoutAssignmentModal, setShowWorkoutAssignmentModal] = useState(false);
  const [showDeleteWorkoutModal, setShowDeleteWorkoutModal] = useState(false);
  const [showWorkoutDetailsPopup, setShowWorkoutDetailsPopup] = useState(false);
  const [showWorkoutEditorModal, setShowWorkoutEditorModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedWorkout, setSelectedWorkout] = useState();
  const [futureLikeAssignments, setFutureLikeAssignments] = useState([]);
  const [copiedWorkout, setCopiedWorkout] = useState(null);
  const [showProgramAssignmentModal, setShowProgramAssignmentModal] = useState(false);
  const [hoverDate, setHoverDate] = useState(null);
  const [mobileUrl, setMobileUrl] = useState('');
  const isComponentMountedRef = useComponentMounted();
  const { showToast } = useToast();
  const WORKOUT_ASSIGNMENT = 'workoutAssignment';
  const DETAILS = 'details';
  const {
    userDoc: clientDoc,
  } = useUserDoc(clientId);
  const { updateLastInteraction } = useUpdateInteraction(clientId);
  const {
    externalCoachDoc: {
      id: coachId,
    },
  } = useContext(ExternalCoachContext);
  const { logCoachingActivity } = useLogger();

  useEffect(() => {
    const loadWorkouts = async () => {
      const workoutCollection = await WorkoutAssignment.getWorkoutAssignmentsForUser(clientId);
      if (isComponentMountedRef.current) {
        setWorkoutAssignments(workoutCollection);
        setShowLoading(false);
      }
    };
    loadWorkouts();
  }, [
    clientId,
    isComponentMountedRef,
  ]);

  const deleteWorkoutAssignment = useCallback(async (workoutAssignment, closePopup) => {
    try {
      closePopup();
      const likeAssignments = workoutAssignments.docs.filter((doc) => (
        doc.name === workoutAssignment.name
        && moment(doc.startDate.toDate()).isSameOrAfter(workoutAssignment.startDate.toDate())
      ));
      const hasMoreLikeAssignments = likeAssignments.length > 1;
      // Show the deletion modal if the assignment is in a program or has similar assignments in the future
      if (workoutAssignment.programId || hasMoreLikeAssignments) {
        if (hasMoreLikeAssignments) {
          setFutureLikeAssignments(likeAssignments);
        }
        setSelectedWorkout(workoutAssignment);
        setShowDeleteWorkoutModal(true);
      } else {
        setShowLoading(true);
        if (workoutAssignment.status === workoutAssignmentStatuses.ASSIGNED) {
          logCoachingActivity(CoachingActivity.DELETED_WORKOUT_ASSIGNMENT, {
            workoutAssignmentId: workoutAssignment.id,
            clientId: workoutAssignment.user,
          });
          await workoutAssignment.delete();
          // Update user's last interaction with the logged in user info
          await updateLastInteraction();
          showToast(texts.deleteSuccess);
        } else {
          showToast(text.inProgressWorkout, { warning: true });
        }
        setShowLoading(false);
      }
    } catch (error) {
      const errMsg = format(texts.deleteError, { msg: error.message });
      showToast(errMsg, { error: true });
      Sentry.captureMessage(errMsg, {
        extra: {
          userId: clientId,
          workoutAssignmentId: workoutAssignment.id || 'undefined',
        },
      });
    }
  }, [
    clientId,
    showToast,
    updateLastInteraction,
    workoutAssignments,
    logCoachingActivity,
  ]);

  const copyWorkoutAssignment = useCallback(async (workout, closePopup) => {
    closePopup();
    setCopiedWorkout(workout);
  }, []);

  const onPasteWorkout = useCallback(async (dateStr) => {
    await WorkoutAssignment.assignWorkout(clientDoc, copiedWorkout, {
      startDate: moment.utc(dateStr).startOf('day'),
      endDate: moment.utc(dateStr).endOf('day'),
      coachId,
    });
    // Update user's last interaction with the logged in user info
    await updateLastInteraction();
    logCoachingActivity(CoachingActivity.ASSIGNED_WORKOUT, {
      workoutId: copiedWorkout.id,
      clientId: clientDoc.id,
    });
    showToast(texts.pasteSuccess);
    if (isComponentMountedRef.current) {
      setShowLoading(false);
    }
  }, [
    isComponentMountedRef,
    copiedWorkout,
    showToast,
    clientDoc,
    updateLastInteraction,
    coachId,
    logCoachingActivity,
  ]);

  const onWorkoutAssignmentClick = useCallback((workoutAssignment) => {
    setMobileUrl(`
      ${config.mobileAppURL}/u/${clientId}/${WORKOUT_ASSIGNMENT}/${workoutAssignment.id}/${DETAILS}?coachId=${coachId}
    `);
    setSelectedWorkout(workoutAssignment);
    setShowWorkoutDetailsPopup(true);
  }, [
    clientId,
    coachId,
  ]);

  const editWorkoutAssignment = useCallback(async (workoutAssignment, closePopup) => {
    closePopup();
    setSelectedWorkout(workoutAssignment);
    setShowWorkoutEditorModal(true);
  }, []);

  const renderEventContent = useCallback((eventInfo) => {
    const workoutAssignment = workoutAssignments.docs.find((w) => w.id === eventInfo.event.id);
    if (!workoutAssignment) return null;
    const isPastWorkout = moment(workoutAssignment.startDate.toDate()).isSameOrBefore(moment(), 'day');
    return (
      <EventContainer>
        {/* show checkbox only for past events */}
        {isPastWorkout && (
          <>
            {workoutAssignment.isCompleted ? <StyledCheckCircleOutline /> : <StyledRadioButtonUnchecked />}
          </>
        )}
        <Tooltip title={workoutAssignment.name} arrow>
          <EventTitle onClick={() => onWorkoutAssignmentClick(workoutAssignment)} className="drag">
            {workoutAssignment.name}
          </EventTitle>
        </Tooltip>
        <PopupState variant="popover">
          {(popupState) => (
            <>
              <StyledIconButton {...bindTrigger(popupState)}>
                <MoreVertIcon />
              </StyledIconButton>
              <Menu {...bindMenu(popupState)}>
                <MenuItem onClick={() => editWorkoutAssignment(workoutAssignment, popupState.close)}>
                  {texts.edit}
                </MenuItem>
                <MenuItem onClick={() => deleteWorkoutAssignment(workoutAssignment, popupState.close)}>
                  {texts.delete}
                </MenuItem>
                <MenuItem
                  onClick={() => copyWorkoutAssignment(
                    {
                      id: workoutAssignment.workout,
                      name: workoutAssignment.workoutContent.name,
                      data: workoutAssignment.workoutContent,
                    },
                    popupState.close,
                  )}
                >
                  {texts.copy}
                </MenuItem>
              </Menu>
            </>
          )}
        </PopupState>
      </EventContainer>
    );
  }, [
    deleteWorkoutAssignment,
    copyWorkoutAssignment,
    workoutAssignments.docs,
    onWorkoutAssignmentClick,
    editWorkoutAssignment,
  ]);

  const workoutCompletionStatus = useMemo(() => (
    workoutAssignments.docs.reduce((acc, doc) => {
      acc[doc.id] = doc?.isCompleted === true;
      return acc;
    }, {})
  ), [workoutAssignments.docs]);

  const transformData = useCallback((workoutAssignment) => ({
    id: workoutAssignment.id,
    title: workoutAssignment.name,
    start: workoutAssignment.momentStartDate.toDate(),
    allDay: true,
    editable: !workoutCompletionStatus[workoutAssignment.id],
    eventDurationEditable: false,
  }), [workoutCompletionStatus]);

  const onDateClick = useCallback(({ dateStr, isProgram = false }) => {
    setSelectedDate(dateStr);
    if (isProgram) {
      setShowProgramAssignmentModal(true);
    } else {
      setShowWorkoutAssignmentModal(true);
    }
  }, []);

  const handleCellMount = useCallback(({ el, date }) => {
    const cellDiv = el;
    cellDiv.onmouseenter = () => setHoverDate(date);
    cellDiv.onmouseleave = () => setHoverDate(null);
  }, []);

  const updateWorkoutAssignmentDates = useCallback(async (id, eventStartDate) => {
    setShowLoading(true);
    const workoutAssigmentDoc = workoutAssignments.docs.find((w) => w.id === id);
    if (workoutAssigmentDoc) {
      await workoutAssigmentDoc.ref.update({
        startDate: moment.utc(eventStartDate).startOf('day').toDate(),
        endDate: moment.utc(eventStartDate).endOf('day').toDate(),
      });
      // Update user's last interaction with the logged in user info
      await updateLastInteraction();
    }
    if (isComponentMountedRef.current) {
      showToast(texts.workoutUpdated);
      setShowLoading(false);
    }
  }, [
    workoutAssignments.docs,
    showToast,
    isComponentMountedRef,
    updateLastInteraction,
  ]);

  const onModalClose = useCallback(() => {
    setShowDeleteWorkoutModal(false);
    setFutureLikeAssignments([]);
  }, []);

  return (
    <div>
      {showWorkoutAssignmentModal && (
        <WorkoutAssignmentModal
          showModal={showWorkoutAssignmentModal}
          onClose={() => setShowWorkoutAssignmentModal(false)}
          clientId={clientId}
          selectedDate={selectedDate}
        />
      )}
      {showDeleteWorkoutModal && (
        <WorkoutDeletionModal
          showModal={showDeleteWorkoutModal}
          onClose={onModalClose}
          selectedWorkout={selectedWorkout}
          workoutAssignments={workoutAssignments}
          futureLikeAssignments={futureLikeAssignments}
        />
      )}
      {showProgramAssignmentModal && (
        <ProgramAssignmentModal
          showModal={showProgramAssignmentModal}
          onClose={() => setShowProgramAssignmentModal(false)}
          clientId={clientId}
          selectedDate={selectedDate}
        />
      )}

      {showWorkoutEditorModal && (
        <WorkoutEditorModal
          showModal={showWorkoutEditorModal}
          workoutAssignment={selectedWorkout}
          editorAction={ActionType.UPDATE_WORKOUT_ASSIGNMENT}
          onSave={() => setShowWorkoutEditorModal(false)}
          onClose={() => setShowWorkoutEditorModal(false)}
        />
      )}
      <CalendarWrapper>
        <FullCalendar
          buttonText={{
            today: texts.today,
          }}
          headerToolbar={{
            right: 'prev,today,next',
          }}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          dayCellContent={(dayData) => (
            <CalendarDayView
              showPasteButton={!!copiedWorkout}
              selectedDate={hoverDate}
              dayData={dayData}
              onWorkoutAssignmentClick={(date) => onDateClick({ dateStr: date })}
              onProgramAssignmentClick={(date) => onDateClick({ dateStr: date, isProgram: true })}
              onWorkoutPasteClick={(date) => {
                onPasteWorkout(date);
              }}
            />
          )}
          events={workoutAssignments.docs.slice()}
          eventContent={renderEventContent}
          eventDataTransform={transformData}
          dayCellDidMount={handleCellMount}
          selectable
          eventDrop={
            (info) => updateWorkoutAssignmentDates(info.event.id, info.event.startStr)
          }
          eventReceive={
            (info) => updateWorkoutAssignmentDates(info.event.id, info.event.startStr)
          }
        />
      </CalendarWrapper>
      {showWorkoutDetailsPopup && (
        <MobileViewModal
          showModal={showWorkoutDetailsPopup}
          onClose={() => setShowWorkoutDetailsPopup(false)}
          mobileUrl={mobileUrl}
        />
      )}
      <LoadingOverlay isLoading={showLoading} />
    </div>
  );
};

WorkoutCalendar.propTypes = {
  clientId: PropTypes.string.isRequired,
};

export default compose(
  observer,
)(WorkoutCalendar);

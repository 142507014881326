import React, {
  useMemo,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { FastField, useFormikContext } from 'formik';
import { IconButton, Tooltip } from '@mui/material';
import { Container as DraggableContainer, Draggable } from 'react-smooth-dnd';
import format from 'string-template';

import { ReactComponent as ReviewIcon } from '../../../../../../../../assets/icons/v2/arrow-right.svg';
import { ReactComponent as EditIcon } from '../../../../../../../../assets/icons/v2/edit-blue.svg';
import { ReactComponent as DragIcon } from '../../../../../../../../assets/icons/v2/drag.svg';
import { PrimaryButton } from '../../../../../../../../components/Button/ActionButtons';
import { AttentionTag, WarningTag } from '../../../../../../../../components/Tags';
import WorkoutContext from '../../../../../../../context/WorkoutContext';
import {
  initialWorkoutDayValues,
} from '../../validation';
import { fieldName } from '../../formFields';
import {
  Label,
} from '../../styles';

import {
  Container,
  StyledFieldArray,
  WorkoutDaysContainer,
  AddNewOptionContainer,
  StyledTypography,
  WorkoutDayIndex,
  StyledOption,
  StyledTextField,
  StyledTrashIcon,
  StyledAddIcon,
  StyledOptionSubContent,
  StyledAutocomplete,
  DraggableContent,
  ButtonContainer,
} from './styles';
import texts from './texts';

const VISIBLE_TAGS_COUNT = 1;

const WorkoutDaysSelector = ({
  workoutDays,
  onPreviewWorkout,
  onEditWorkout,
  onClosePreview,
}) => {
  const { filteredWorkouts } = useContext(WorkoutContext);

  const { values, setFieldValue } = useFormikContext();

  const workoutDaysList = values[fieldName.WORKOUT_DAYS];

  /**
   * Updates the equipment list based on the selected workouts in the workout days.
   *
   * Depending on the selected workout, this function updates the value of the formik field
   * 'equipment' with unique equipment items.
   */
  useEffect(() => {
    let currentEquipmentList = workoutDaysList
      .flatMap((day) => {
        if (day.workout) {
          return day.workout.equipmentList;
        }
        return [];
      });

    currentEquipmentList = Array.from(new Set(currentEquipmentList));
    setFieldValue(fieldName.EQUIPMENT, currentEquipmentList);
  }, [
    setFieldValue,
    workoutDaysList,
  ]);

  const renderEquipmentChip = useCallback((equipment) => {
    if (equipment && equipment.length > 0) {
      const firstEquipmentItem = equipment.slice(0, VISIBLE_TAGS_COUNT);
      const remainingEquipment = equipment.slice(VISIBLE_TAGS_COUNT, equipment.length);
      const tooltipText = remainingEquipment.join(', ');
      return (
        <>
          {remainingEquipment.length ? (
            <Tooltip title={tooltipText} placement="top" arrow>
              <WarningTag>
                {format(texts.equipment,
                  { equipment: `${firstEquipmentItem[0]}, ${texts.moreEquipment}` })}
              </WarningTag>
            </Tooltip>
          ) : (
            <WarningTag>{format(texts.equipment, { equipment: firstEquipmentItem[0] })}</WarningTag>
          )}
        </>
      );
    }
    return <WarningTag>{format(texts.equipment, { equipment: texts.none })}</WarningTag>;
  }, []);

  const renderTimeChip = useCallback((estimatedDurationInMinutes) => (
    <AttentionTag>{format(texts.estDuration, { minutes: estimatedDurationInMinutes })}</AttentionTag>
  ), []);

  const renderInput = useCallback((params, index) => (
    <StyledTextField
      {...params}
      label={texts.textFieldLabel}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            {renderEquipmentChip(workoutDaysList[index].equipment)}
            {!!workoutDaysList[index].workout
            && !!workoutDaysList[index].workout.estimatedDurationInMinutes && (
              renderTimeChip(
                workoutDaysList[index].workout.estimatedDurationInMinutes,
              )
            )}
            {params.InputProps.endAdornment}
          </>
        ),
      }}
    />
  ), [
    renderEquipmentChip,
    renderTimeChip,
    workoutDaysList,
  ]);

  const onWorkoutChange = useCallback((value, index) => {
    onClosePreview();
    setFieldValue(`${fieldName.WORKOUT_DAYS}[${index}]`, value);
  }, [
    onClosePreview,
    setFieldValue,
  ]);

  const workoutOptions = useMemo(() => filteredWorkouts.map((workout) => ({
    id: workout.id,
    label: workout.name,
    equipment: workout.equipmentList,
    workout,
  })), [filteredWorkouts]);

  return (
    <Container>
      <Label>{`${texts.title}:`}</Label>
      <StyledFieldArray
        name={fieldName.WORKOUT_DAYS}
        render={({
          remove,
          push,
          move,
        }) => (
          <>
            <DraggableContainer
              dragHandleSelector=".drag-handle"
              lockAxis="y"
              onDrop={({ removedIndex, addedIndex }) => move(removedIndex, addedIndex)}
            >
              {workoutDays.map((day, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Draggable key={index}>
                  <DraggableContent>
                    <WorkoutDaysContainer>
                      <IconButton className="drag-handle">
                        <DragIcon />
                      </IconButton>
                      <WorkoutDayIndex>{`${texts.day} ${index + 1}`}</WorkoutDayIndex>
                      <FastField name={`${fieldName.WORKOUT_DAYS}.${index}`}>
                        {({ field }) => (
                          <StyledAutocomplete
                            {...field}
                            disablePortal
                            disableClearable
                            options={workoutOptions.concat(initialWorkoutDayValues)}
                            noOptionsText={texts.noOptionText}
                            onChange={(value) => onWorkoutChange(value, index)}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            renderInput={(params) => renderInput(params, index)}
                            renderOption={(props, {
                              id,
                              label,
                              equipment,
                              workout,
                            }) => (
                              <StyledOption {...props} key={id} className="workout-option">
                                {label}
                                <StyledOptionSubContent>
                                  {renderEquipmentChip(equipment)}
                                  {!!workout && !!workout.estimatedDurationInMinutes && (
                                    renderTimeChip(workout.estimatedDurationInMinutes)
                                  )}
                                </StyledOptionSubContent>
                              </StyledOption>
                            )}
                          />
                        )}
                      </FastField>
                      <IconButton onClick={() => remove(index)}>
                        <StyledTrashIcon />
                      </IconButton>
                    </WorkoutDaysContainer>
                    { day.label !== initialWorkoutDayValues.label
                    && (
                    <ButtonContainer>
                      <PrimaryButton
                        onClick={() => onEditWorkout(day.workout.id)}
                        icon={<EditIcon />}
                        variant="info"
                        size="medium"
                      >
                        {texts.editWorkout}
                      </PrimaryButton>
                      <PrimaryButton
                        onClick={() => onPreviewWorkout(day.workout.id)}
                        endIcon={<ReviewIcon />}
                        variant="info"
                        size="medium"
                      >
                        {texts.viewWorkout}
                      </PrimaryButton>
                    </ButtonContainer>
                    )}
                  </DraggableContent>
                </Draggable>
              ))}
            </DraggableContainer>
            <AddNewOptionContainer>
              <IconButton onClick={() => push(initialWorkoutDayValues)}>
                <StyledAddIcon />
              </IconButton>
              <StyledTypography>{texts.addNewDay}</StyledTypography>
            </AddNewOptionContainer>
          </>
        )}
      />
    </Container>
  );
};

WorkoutDaysSelector.propTypes = {
  workoutDays: PropTypes.array.isRequired,
  onPreviewWorkout: PropTypes.func.isRequired,
  onEditWorkout: PropTypes.func.isRequired,
  onClosePreview: PropTypes.func.isRequired,
};

export default WorkoutDaysSelector;
